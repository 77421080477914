import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import HealthBanner from "../components/healthBanner";

class HealthWorkingGroup extends Component {
    render() {
        return (
            <Layout headerElement={<HealthBanner />}>
                <SEO title="Health Working Group" />
                <section id="section3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="img-box">
                                    <div className="img-box__img">
                                        <img className="img--bg m-b-10"  alt="health" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/health.png" style={{height: '42%', marginTop: 125}} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 mt-5">
                                <h2 style={{fontSize: '40px !important'}}><img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/aban-small.png" width="40" /> Health Strategy</h2>
                                <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px'}}>
                                    <li style={{marginBottom: 12}}>The need for health data (empirical and/or quantitative) that is reflective of the ANS population</li>
                                    <li style={{marginBottom: 12}}>Health system has yet to reflect culturally competent planning and practice.</li>
                                    <li className="d-block" style={{marginBottom: 12}}>
                                        <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px', marginTop: 18}}>
                                            <li>Lack of cultural competency and underrepresentation of ANS health professionals at all levels</li>
                                        </ul>
                                    </li>
                                    <li>Little evidence of culturally specific applications in health policy and program (ex: brotherhood initiative underfunded and no sisterhood initiative)</li>

                                </ul>
                                <div className="widget-title recommended" style={{textDecoration: 'underline'}}>Recommendations</div>
                                <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px', marginTop: 18}}>
                                    <li style={{marginBottom: 12}}>Collection of health data quantitative &amp; qualitative health that examines the intersections of race, ethnicity, culture, gender identity, income, citizenship status, sexual orientation, language &amp; disability etc.</li>
                                    <li style={{marginBottom: 12}}>Establish health system planning that supports professionals to practice in culturally competent ways, understanding clinical cultural competence related to African Canadian health needs, disparities and inequities</li>
                                    <li style={{marginBottom: 12}}>Hire many more African-descended health professionals within government, clinically, administratively, at management levels and at the very least, one representative at the NSHA Board level</li>
                                </ul>
                                <div className="clear"></div>
                                <div className="seperator"><i className="fa fa-medkit"></i></div>
                            </div>
                        </div>

                    </div>
                </section>
            </Layout>
        );
    }
}

export default HealthWorkingGroup